/* MEDIUM SCREENS */
@media screen and (width <= 1100px){
    body{
        font-size: 16px;
        overflow-x: hidden;
      }
      /* 1.200 Minor Third with a body size of 16 px */
      h1{
        font-size: 2.6rem;
      }
      h2{
        font-size: 2.488rem;
      }
      h3{
        font-size: 2.074rem;
        clear: le
      }
      h4{
        font-size:1.728rem;
      }
      h5{
        font-size: 1.44rem;
      }
      h6{
        font-size:1.2rem;
      }
      a{
        font-size: 1.2rem;
      }
      p{
        font-size: 1rem;
      }
      main{
        height: 100vh;
      }
      /* Could need to be changed */
      textarea{
        resize: none;
        height: 50px;
        width: 150px;
      }
      label{
        font-size: 1.2rem;
      }
      span, .italicSpan{
        font-size: 1rem;
      }
      /* Primary Button */
      /* .primaryButton {
        padding: 0.5rem 1rem;
        min-width: 120px;
        max-width: 200px;
      } */
      /* Secondary Button */
      /* .secondaryButton {
        padding: 0.5rem 1rem;
        min-width: 120px;
        max-width: 200px;
      } */
      /* .thumbnail{
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        max-height: 150px;
        min-height: 150px;
        height: auto;
      } */

      .tabButton {
        min-width: 180px;
        height: 50px;

      }
      .tabButton span{
        font-size: 1rem;
      }
      .tabButton span, .tabButtonActive span{
        letter-spacing: 0.1rem;
        font-size: 1rem;
      }
      .tabButtonActive{
        min-width: 180px;
        height: 50px;
      }
              /* Tab Button used for dashboard*/
          .tabButton {
            min-width: 190px;
            min-height: 50px;
          }
          .tabButtonActive{
            min-width: 190px;
            max-width: 350px;
            min-height: 50px;
            border: 5px solid var(--yellow5);
          }
      .correct{
        min-width: 350px;
        width: 350px;
        max-width: 350px;
      }
      .nav-icon{
        height: 40px;
        width: 40px;
      }
}