/* SMALLEST SCREENS */
@media screen and (width <= 600px){
    body{
        font-size: 16px;
        overflow-x: hidden;
      }
      /* 1.067 Minor Second with a 16px body */
      h1{
        font-size: 1.776rem;
      }
      h2{
        font-size: 1.583rem;
      }
      h3{
        font-size: 1.396rem;
      }
      h4{
        font-size: 1.215rem;
      }
      h5{
        font-size: 1.138rem;
      }
      h6{
        font-size: 1.067rem;
      }
      p{
        font-size: 1rem;
      }
      span, .italicSpan{
        font-size: 0.9rem;

      }
      a{
        font-size: 1rem;
        font-family: 'Noto_Sans', sans-serif;
      }
      main{
        height: 100vh;
      }
      textarea{
        resize: none;
        height: 50px;
        width: 150px;
      }
      label{
        font-size: 1rem;
      }
      
      /* Primary Button */
      /* .primaryButton {
        padding: 0.5rem 1rem;
        min-width: 100px;
        max-width: 180px;
      } */
      /* Secondary Button */
      /* .secondaryButton {
        padding: 0.5rem 1rem;
        min-width: 100px;
        max-width: 180px;
      } */

      .thumbnail{
        max-width: 200px;
        min-width: 200px;
        width: 200px;
        max-height: 150px;
        min-height: 150px;
        height: auto;
      }

      .icon{
        height: 30px;
        width: 30px;
      }
      .iconMenu{
        height: 35px;
        width: 35px;
      }

 

}

/* Base font size for the entire document */
html {
  font-size: 16px; /* 1rem = 16px */
}

/* Small Screens (Mobile) */
@media screen and (max-width: 600px) {
  body {
    font-size: 1rem; /* 16px */
    overflow-x: hidden;
  }
  h1 {
    font-size: 1.776rem; /* 28.416px */
  }
  h2 {
    font-size: 1.583rem; /* 25.328px */
  }
  h3 {
    font-size: 1.396rem; /* 22.336px */
  }
  h4 {
    font-size: 1.215rem; /* 19.44px */
  }
  h5 {
    font-size: 1.138rem; /* 18.208px */
  }
  h6 {
    font-size: 1.067rem; /* 17.072px */
  }
  p {
    font-size: 1rem; /* 16px */
  }
  label {
    font-size: 0.875rem; /* 14px */
  }
  button {
    font-size: 1rem; /* 16px */
  }
}

/* Medium Screens (Tablet) */
@media screen and (min-width: 601px) and (max-width: 1024px) {
  body {
    font-size: 1.125rem; /* 18px */
  }
  h1 {
    font-size: 2.25rem; /* 40.5px */
  }
  h2 {
    font-size: 2rem; /* 36px */
  }
  h3 {
    font-size: 1.75rem; /* 31.5px */
  }
  h4 {
    font-size: 1.5rem; /* 27px */
  }
  h5 {
    font-size: 1.375rem; /* 24.75px */
  }
  h6 {
    font-size: 1.25rem; /* 22.5px */
  }
  p {
    font-size: 1.125rem; /* 18px */
  }
  label {
    font-size: 1rem; /* 16px */
  }
  button {
    font-size: 1.125rem; /* 18px */
  }
}

/* Large Screens (Desktop) */
@media screen and (min-width: 1025px) {
  body {
    font-size: 1.25rem; /* 20px */
  }
  h1 {
    font-size: 2.5rem; /* 50px */
  }
  h2 {
    font-size: 2.25rem; /* 45px */
  }
  h3 {
    font-size: 2rem; /* 40px */
  }
  h4 {
    font-size: 1.75rem; /* 35px */
  }
  h5 {
    font-size: 1.625rem; /* 32.5px */
  }
  h6 {
    font-size: 1.5rem; /* 30px */
  }
  p {
    font-size: 1.25rem; /* 20px */
  }
  label {
    font-size: 1.125rem; /* 18px */
  }
  button {
    font-size: 1.25rem; /* 20px */
  }
}