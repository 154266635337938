/* BIGGER SCREEN */
/* 
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Oswald:wght@200..700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Carme&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap'); */

@font-face {
  font-family: 'Lato';
  src: url('./Visuals/Fonts/Lato/Lato-Regular.ttf') format('woff2');
 }

 @font-face {
  font-family: 'Oswald';
  src: url('./Visuals/Fonts/Oswald/Oswald-VariableFont_wght.ttf') format('woff2');
 }
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
 
}
:root{
  --headerFont: 'Oswald', serif;
  --mainFont: 'Lato', sans-serif; 
  --white: #FEFEFE;
  --offWhite: #F3F3F3;
  --grey: #e6e6e6;
  --grey1: #D1CECE;
  --grey2: #B3B0B0;
  --grey3: #757575;
  --grey4: #515151;
  --grey5: #343434;
  --grey6: #262626;
  --grey7: #171717; 
  --yellow1: #FDDF6D;
  --yellow2: #FDDC5E;
  --yellow3: #FDD84C;
  --yellow4: #FED242;
  --yellow5: #FFC933;
  --orange1: #F6D29D;
  --orange2: #F2AF17;
  --orange3: #EDA845;
  --orange4: #EA9A26;
  --orange5: #E68B07;
  --orange6: #DC8507;
  --black: #1D1D1D;
  --lightRed: #ffe4e5;
  --errorRed: #d01124;
  --lightGreen: #eefeef;
  --successGreen: #3b8239;
} 
html {
  font-size: 16px;
}
body{
  background-color: var(--white);
  color: var(--grey5);
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  accent-color: var(--orange2);
  overflow-x: hidden;
  margin: 0;
}
main{
  height: 100vh;
  overflow-y: scroll;
  width: 100%;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  
}
h1{
  font-size: 2.986rem;
  font-weight: 800;
  font-family: 'Oswald', sans-serif;
  color: var(--grey7);
  letter-spacing: 0.1rem;
}
h2 {
  font-size: 2.488rem;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--grey6);
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.1rem;
}
h2::after {
  content: '';
  display: block;
  width: 100%;
  height: 5px;
  background-color: var(--yellow5);
}
h3{
  font-size: 2.074rem;
  color: var(--grey5);
  font-weight: 600;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.1rem;
}
h4{
  font-size:1.728rem;
  font-weight: 500;
  font-family: 'Oswald', sans-serif;
  letter-spacing: 0.1rem;
}
h5{
  font-size: 1.44rem;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
}
h6{
  font-size:1.2rem;
  font-weight: 400;
  font-family: 'Lato', sans-serif;;
}
p{
  font-size: 1.1rem;
  font-weight: normal;
  font-family: 'Lato', sans-serif;;
}
span{
    font-size: 1.1rem;
    font-weight: normal;
    color: var(--grey4);
}
.italicSpan{
  font-style: italic;
  font-size: 1.1rem;
  color: var(--grey4);
}
li{
  border-bottom: #1D1D1D;
  color: var(--grey4);
  letter-spacing: 0.1rem;
}
a{
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.2rem;
  color: var(--grey);
  text-decoration: none;
}
a:hover{
  color: var(--yellow5);
}
a:active{
  color: var(--yellow5);
}
.subLink:hover {
  color: var(--orange2);
}
/* Form Elements */
textarea{
  resize: none;
  height: 80px;
  min-width: 30%;
  max-width: 50%;
  font-family: 'Noto_Sans', sans-serif;
  border: 2px solid var(--grey5);
  border-radius: 5px;
}
label{
  font-size: 1.3rem;
  font-weight: 700;
  font-family: 'Noto_Sans', sans-serif;
  color: var(--grey5);
}
input, textarea{
  font-family: 'Noto_Sans', sans-serif;
  border: 1px solid var(--grey);
  border-radius: 5px;
  padding: 0.6rem;
  width: auto;
  margin-right: 0.5rem;
  box-shadow: inset 1px 2px 3px hsla(0, 0%, 0%, 0.1);
}
button {
  font-size: 1rem;
}
 button span {
   font-size: 1rem;
}
input:focus, textarea:focus{
  border: 1px solid var(--yellow2);
  outline: none;
  border-color: var(--yellow2);
}
input[type="input"] {
  box-shadow: 1px 0 10px 2px var(--yellow3);

}
input[type="checkbox"] {
  box-shadow: none;
}
input[type="radio"] {
  box-shadow: none;
}
input[type="file"] {
  background-color: var(--grey);
}
select {
  font-family: 'Noto_Sans', sans-serif;
  border: 3px solid var(--orange1);
  background-color: var(--orange2);
  border-radius: 5px;
  padding: 0.6rem;
  font-size: 1rem;
  width: 20%;
}
.required {
  color: var(--orange4);
}




